<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <Redirect title="Payout" route="website.settings.payout" />
      <h2 class="mt-2" style="font-size: 32px">Add funds</h2>
      <p class="mb-4">
        The top-up amount will be added to your Balance a few minutes after the payment process is completed. For Money
        Transfer, it may take up to 24 hours for the amount to show in your Balance.
      </p>

      <v-card outlined class="pa-4">
        <div class="d-flex justify-space-between">
          <div class="mr-4">
            <h3>Top up</h3>
            <p>Choose amount to add by clicking on the option below</p>
          </div>
          <div class="amount">
            <span class="text-no-wrap">Available amount</span>
            <h3>{{ formatMoney(balance) }}</h3>
          </div>
        </div>
        <v-divider class="mt-2 mb-8"></v-divider>
        <ul class="price-list mb-2">
          <li
            v-for="price in priceList"
            :key="price"
            :class="form.fields.amount === price ? 'active' : ''"
            @click="form.fields.amount = price"
          >
            {{ formatMoney(price) }}
            <span class="popular-tag" v-if="price === 40">Popular</span>
          </li>
        </ul>
        <v-form ref="formRef">
          <v-row>
            <v-col class="form-component">
              <label>Amount (*) </label>
              <v-text-field
                autocomplete="off"
                prefix="$"
                v-model.number="form.fields.amount"
                type="number"
                :min="0"
                :rules="form.rules.amount"
                @blur="fixAmount"
              ></v-text-field>
            </v-col>
            <v-col class="form-component">
              <label>Pay width (*)</label>
              <v-select v-model="form.fields.payWith" :items="payWidthList" no-data-text="No data"></v-select>
            </v-col>
          </v-row>
          <div class="form-component">
            <label>Transaction ID (*)</label>
            <v-text-field
              autocomplete="off"
              v-model="form.fields.transactionId"
              :rules="form.rules.transactionId"
            ></v-text-field>
          </div>
          <div class="form-component">
            <label>Note</label>
            <v-textarea v-model="form.fields.note" :rows="2" no-resize></v-textarea>
          </div>
        </v-form>

        <div class="d-flex justify-end mt-3">
          <v-btn :loading="form.loading" color="primary" depressed @click="submit">Save</v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Redirect from '@/components/RedirectTo';
import TopUpService from '@/apis/topUp';
export default {
  components: {
    Redirect,
  },
  data() {
    return {
      priceList: [10, 20, 40, 80, 100, 200, 500, 1000, 10000],
      payWidthList: ['Payoneer', 'PingPong', 'Bank Transfer'],
      form: {
        loading: false,
        fields: {
          amount: 0,
          payWith: 'Payoneer',
          transactionId: null,
          note: null,
        },
        rules: {
          amount: [v => v >= 0 || 'Amount is not valid'],
          transactionId: [v => (v && v.length > 0) || 'Transaction ID is required'],
        },
      },
      balance: 0,
    };
  },
  methods: {
    fixAmount() {
      if (this.form.fields.amount === '' || this.form.fields.amount < 0) {
        this.form.fields.amount = 0;
      }
    },
    formatMoney(money) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      let result = formatter.format(money);
      result = result.toString().slice(0, -3);
      return result;
    },
    async getBalance() {
      try {
        const { data } = await TopUpService.getBalance();
        this.balance = data;
      } catch (error) {
        console.log('🚀 ~ file: index.vue:120 ~ getBalance ~ error:', error);
      }
    },
    async submit() {
      if (this.$refs.formRef.validate()) {
        try {
          this.form.loading = true;
          const { data } = await TopUpService.createTopUp(this.form.fields);
          if (data) {
            this.$router.push({ name: 'website.settings.payout' });
          }
        } catch (error) {
          console.log('🚀 ~ file: index.vue:115 ~ submit ~ error:', error);
        } finally {
          this.form.loading = false;
        }
      }
    },
  },
  created() {
    this.getBalance();
  },
};
</script>

<style scoped>
.amount {
  border-radius: 4px;
  text-align: center;
  width: fit-content;
}
.amount h3 {
  font-size: 24px;
}
ul.price-list {
  padding-left: 0px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 65px);
  grid-gap: 20px 20px;
}
ul.price-list li {
  border: solid 1px lightgray;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  position: relative;
}
ul.price-list li.active {
  background: #fcebcf;
}
ul.price-list li span.popular-tag {
  background-color: #b49b72;
  color: #fff;
  font-size: 12px;
  position: absolute;
  border-radius: 4px;
  padding: 2px 6px;
  top: -12px;
}
>>> .v-text-field__prefix {
  padding-left: 16px !important;
  padding-right: 0 !important;
}
</style>
