import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
const storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
const topUpService = {
  createTopUp(data) {
    return storeRequest.post('/users/deposits/seller/create', data);
  },
  getBalance() {
    return storeRequest.get('/users/balance/seller');
  },
  getDepositHistory(data) {
    return storeRequest.get('/users/deposits/seller/all', {
      params: data,
    });
  },
};
export default topUpService;
